<template>
  <bookings>
    <BookingOpenedCard/>
  </bookings>
</template>

<script>
import Bookings from '@/views/Bookings.vue'
import BookingOpenedCard from '@/components/Bookings/BookingOpenedCard.vue'

export default {
  name: 'BookingsOpened',
  components: {
    Bookings,
    BookingOpenedCard
  },
  created () {
    if (!this.loggedUser.getBusiness.isBookingCreator) {
      this.$router.push({ name: 'community' })
    }
  },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    }
  }
}
</script>
