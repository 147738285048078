<template>
  <div>
    <b-card
      align="left"
      class="booking-opened-card side-card"
      :class="{ 'has-bookings': areThereBookings }"
      header-tag="header"
      no-body
    >
      <template #header>
        <b-row>
          <b-col>
            <h5>Bookings abiertos</h5>
            <div>
              <b-button
                v-b-modal.booking-modal
                class="float-right small d-inline-block d-sm-none"
                type="button"
                variant="outline"
              >
                <img :src="require('@/assets/icons/create-icon.svg')">
              </b-button>
              <b-button
                v-b-modal.booking-modal
                class="float-right small d-none d-sm-inline-block"
                type="button"
                variant="outline"
                v-if="areThereBookings"
              >
                <img :src="require('@/assets/icons/create-icon.svg')">
              </b-button>
              <b-button
                v-b-modal.booking-modal
                class="float-right big text-nowrap d-none d-sm-inline-block"
                type="button"
                variant="primary"
                v-else
              >
                <img :src="require('@/assets/icons/create-icon.svg')">
                Crear booking
              </b-button>
              <modal-component
                id="booking-modal"
                title="Crear booking"
                ok-title="Siguiente"
                size="lg"
                @confirmed="onCreateBooking"
              >
                <add-booking-info
                  ref="addBooking"
                  :booking="booking"
                />
              </modal-component>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="booking-wrapper" v-if="!areThereBookings">
              <div>
                <strong>¡Vaya!</strong>
              </div>
              Parece que no tienes bookings abiertos
            </div>
          </b-col>
        </b-row>
      </template>
      <b-card-body v-if="areThereBookings" class="booking-list">
        <b-container>
          <b-row v-for="booking in bookings" :key="booking.id">
            <b-col cols="3" sm="2">
              <div class="booking-code">
                {{ booking.getCode }}
              </div>
            </b-col>
            <b-col cols="9" sm="7">
              <div class="booking-name">{{ booking.getName }}</div>
              <div class="booking-event-date">{{ booking.getEventDate }}</div>
              <div class="booking-location">{{ booking.getShortLocation }}</div>
            </b-col>
            <b-col cols="12" sm="3" class="mt-4 mt-sm-0">
              <b-button
                class="float-right w-100"
                :to="{ name: 'view-booking', params: { id: booking.getId } }"
                variant="secondary">
                Ver
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>
    <b-card
      align="left"
      class="booking-opened-card side-card"
      :class="{ 'has-bookings': areThereDraftBookings }"
      header-tag="header"
      no-body
    >
      <template #header>
        <b-row>
          <b-col>
            <h5>Bookings en borrador</h5>
            <div>
              <b-button
                v-b-modal.booking-modal
                class="float-right small d-inline-block d-sm-none"
                type="button"
                variant="outline"
              >
                <img :src="require('@/assets/icons/create-icon.svg')">
              </b-button>
              <b-button
                v-b-modal.booking-modal
                class="float-right small d-none d-sm-inline-block"
                type="button"
                variant="outline"
                v-if="areThereDraftBookings"
              >
                <img :src="require('@/assets/icons/create-icon.svg')">
              </b-button>
              <b-button
                v-b-modal.booking-modal
                class="float-right big text-nowrap d-none d-sm-inline-block"
                type="button"
                variant="primary"
                v-else
              >
                <img :src="require('@/assets/icons/create-icon.svg')">
                Crear booking
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="booking-wrapper" v-if="!areThereDraftBookings">
              <div>
                <strong>¡Vaya!</strong>
              </div>
              Parece que no tienes bookings en borrador
            </div>
          </b-col>
        </b-row>
      </template>
      <b-card-body v-if="areThereDraftBookings" class="booking-list">
        <b-container>
          <b-row v-for="booking in draftBookings" :key="booking.id">
            <b-col cols="3" sm="2">
              <div class="booking-code">
                {{ booking.getCode }}
              </div>
            </b-col>
            <b-col cols="9" sm="7">
              <div class="booking-name">{{ booking.getName }}</div>
              <div class="booking-event-date">{{ booking.getEventDate }}</div>
              <div class="booking-location">{{ booking.getShortLocation }}</div>
            </b-col>
            <b-col cols="12" sm="3" class="mt-4 mt-sm-0">
              <b-button
                class="float-right w-100"
                :to="{ name: 'view-booking', params: { id: booking.getId } }"
                variant="secondary">
                Ver
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import ModalComponent from '@/components/Modal/ModalComponent.vue'
import BookingFactory from '@/domain/services/BookingFactory'
import AddBookingInfo from '@/components/Bookings/AddBookingInfo.vue'

export default {
  name: 'BookingOpenedCard',
  components: {
    ModalComponent,
    AddBookingInfo
  },
  data () {
    return {
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    },
    booking () {
      return BookingFactory.getBooking({
        createdBy: this.loggedUser.getCompleteName,
        eventType: null,
        docs: [],
        positions: []
      })
    },
    bookings () {
      return this.$store.getters.bookingOpened.filter((booking) => {
        return booking.getBroadcasted
      })
    },
    draftBookings () {
      return this.$store.getters.bookingOpened.filter((booking) => {
        return !booking.getBroadcasted
      })
    },
    areThereBookings () {
      return this.bookings.length > 0
    },
    areThereDraftBookings () {
      return this.draftBookings.length > 0
    }
  },
  methods: {
    loadData () {
      this.$store.dispatch('getBookingOpened')
    },
    async onCreateBooking () {
      const valid = await this.$refs.addBooking.$refs.addBookingInfoForm.validate()
      if (!valid) {
        return
      }
      this.$store.dispatch('createBooking', this.booking)
        .then((booking) => {
          this.$store.dispatch('getBookingOpened')
            .then(() => {
              this.$router.push({
                name: 'view-booking',
                params: {
                  id: booking.id
                }
              })
            })
          this.$bvModal.hide('booking-modal')
          this.$store.dispatch('showConfirmationModal')
        })
    }
  }
}
</script>
<style lang="less" scoped>
  @import 'booking-opened.less';
  .btn-outline {
    outline: none;
    img {
      outline: none;
    }
  }
  .booking-wrapper {
    margin-top: 1.3rem;
    margin-bottom: 6.25rem;
  }
  .booking-opened-card {
    header.card-header {
      padding-top: 0.70rem !important;
      padding-bottom: 0.70rem !important;
      button.small {
        padding: 0;
      }
    }
  }
</style>
